import React from 'react';

export const ErrorAlert: React.FC<{
  message: string;
  onClose?: () => void;
}> = ({ onClose, message }) => {
  return (
    <div
      role="alert"
      className="mt-4 flex items-center justify-between p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50"
    >
      <div className="flex items-center">
        <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5 mr-2">
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M18 10A8 8 0 11 2 10a8 8 0 0116 0zm-8-4a1 1 0 00-.993.883L9 7v4a1 1 0 001.993.117L11 11V7a1 1 0 00-1-1zm0 8a1 1 0 100-2 1 1 0 000 2z"
          />
        </svg>
        <span>{message}</span>
      </div>

      {onClose && (
        <button
          type="button"
          className="ml-4 text-red-800 hover:text-red-900"
          onClick={onClose}
        >
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export const SuccessAlert: React.FC<{
  message: string;
  onClose?: () => void;
}> = ({ onClose, message }) => {
  return (
    <div
      role="alert"
      className="mt-4 flex items-center justify-between p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 border border-green-200"
    >
      <div className="flex items-center">
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          className="w-5 h-5 mr-2 text-green-800"
        >
          <path
            clipRule="evenodd"
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414L9 14.414 5.293 10.707a1 1 0 011.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z"
          />
        </svg>
        <span>{message}</span>
      </div>

      {onClose && (
        <button
          type="button"
          className="ml-4 text-green-800 hover:text-green-900 focus:outline-none"
          onClick={onClose}
        >
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            />
          </svg>
        </button>
      )}
    </div>
  );
};
