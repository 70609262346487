import React from 'react';

const icon = (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-spin h-5 w-5 mr-2 text-white"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      strokeWidth="4"
      stroke="currentColor"
      className="opacity-25"
    />
    <path
      fill="currentColor"
      className="opacity-75"
      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
    />
  </svg>
);

export const Button: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
  }
> = ({ loading, disabled, children, ...rest }) => {
  const className = `primary-btn text-white w-full h-12 py-0 rounded-[30px] flex items-center justify-center ${
    disabled ? 'opacity-25 cursor-not-allowed' : ''
  }`;

  return (
    <button
      type="submit"
      className={className}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? icon : children}
    </button>
  );
};
