'use client';

import React from 'react';
import { Forms } from '@shared/types/forms';
import { Button } from '@shared/ui/tailwind/button';
import { Control, useWatch, useFormState } from 'react-hook-form';

export const SubmitButton: React.FC<{
  control: Control<Forms.Login>;
}> = ({ control }) => {
  const { isSubmitting, isSubmitSuccessful } = useFormState({ control });
  const [email, password] = useWatch({ control, name: ['email', 'password'] });

  const disabled =
    !password?.trim() || !email?.trim() || isSubmitting || isSubmitSuccessful;

  return (
    <div className="mt-6 flex flex-col items-center">
      <Button
        type="submit"
        disabled={disabled}
        loading={isSubmitting || isSubmitSuccessful}
      >
        Sign In
      </Button>
    </div>
  );
};
