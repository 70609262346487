'use client';

import React from 'react';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { AppRoutes } from '@shared/constants/appRoutes';

const Component = () => {
  const params = useSearchParams();
  const paramsString = params.toString();

  let href: AppRoutes | string = AppRoutes.forgotPassword;

  if (paramsString) {
    href = `${href}?${paramsString}`;
  }

  return (
    <div className="mt-4 text-sm">
      <p className="text-gray-500">
        <Link
          href={href}
          className="text-indigo-600 font-medium hover:underline"
        >
          Forgot your password?
        </Link>
      </p>
    </div>
  );
};

export const ForgotPassword = () => {
  return (
    <React.Suspense>
      <Component />
    </React.Suspense>
  );
};
