import React from 'react';
import Image from 'next/image';
import googleLogo from '@shared/auth/images/google-logo.r0ky5Kfn.svg';

export const GoogleButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
  }
> = ({ disabled, children, ...rest }) => {
  const className = `group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-blue-400 focus:bg-blue-50 active:bg-blue-100 w-full ${
    disabled ? 'opacity-25 cursor-not-allowed' : ''
  }`;

  return (
    <button
      type="button"
      disabled={disabled}
      className={className}
      style={disabled ? { pointerEvents: 'none' } : void 0}
      {...rest}
    >
      <div className="relative flex items-center space-x-4 justify-center">
        <Image src={googleLogo} alt="Google logo" />
        <span className="block font-semibold tracking-wide text-gray-700 text-sm transition duration-300 group-hover:text-blue-600 sm:text-base">
          {children}
        </span>
      </div>
    </button>
  );
};
