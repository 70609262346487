'use client';

import React from 'react';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { AppRoutes } from '@shared/constants/appRoutes';

const Component: React.FC = () => {
  const params = useSearchParams();
  const paramsString = params.toString();

  let href: AppRoutes | string = AppRoutes.registerStep1;

  if (paramsString) {
    href = `${href}?${paramsString}`;
  }

  return (
    <div className="mt-8 text-center text-sm">
      <p className="text-gray-500 flex justify-center gap-2">
        <span>Don’t have an account?</span>
        <Link
          href={href}
          className="text-indigo-600 font-medium hover:underline"
        >
          Sign up now!
        </Link>
      </p>
    </div>
  );
};

export const SignUp: React.FC = () => {
  return (
    <React.Suspense>
      <Component />
    </React.Suspense>
  );
};
