'use client';

import React from 'react';
import { Forms } from '@shared/types/forms';
import { useRouter } from 'next/navigation';
import { Controller, useForm } from 'react-hook-form';
import { SignUp } from '@shared/auth/ui/login/sign-up';
import { AppRoutes } from '@shared/constants/appRoutes';
import { postMessage } from '@shared/utils/post-message';
import { sendGTMEvent } from '@next/third-parties/google';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { SubmitButton } from '@shared/auth/ui/login/submit-button';
import { ErrorAlert, SuccessAlert } from '@shared/ui/tailwind/alert';
import { ForgotPassword } from '@shared/auth/ui/login/forgot-password';
import { useMeMutation, useLoginMutation } from '@shared/services/auth';
import { ContinueWithGoogle } from '@shared/auth/ui/login/continue-with-google';
import {
  setMessage,
  useDispatch,
  useSelector,
  selectMessage,
} from '@shared/redux';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { replace } = useRouter();
  const postHog = useLocalPostHog();
  const [fetchMe] = useMeMutation();
  const [submit] = useLoginMutation();

  const message = useSelector(selectMessage);

  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<Forms.Login>({
    mode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  });

  const rootMessage = errors?.root?.message;

  const onSubmit = async (formValues: Forms.Login) => {
    dispatch(setMessage(undefined));

    try {
      const loginResponse = await submit(formValues).unwrap();

      const meResponse = await fetchMe().unwrap();

      const authUser = meResponse?.data?.user;

      postHog?.capture(PosthogEvents.login, {
        email: authUser.email || '',
        handle: authUser.handle || '',
      });

      sendGTMEvent({
        event: 'login',
        email: authUser.email || '',
        handle: authUser.handle || '',
      });

      postMessage(loginResponse.data.token || '', {
        name: authUser.name,
        email: authUser.email,
        avatar: authUser.profile_photo_path,
      });

      return replace(AppRoutes.base);
    } catch (e) {
      if (e && typeof e === 'object' && 'message' in e) {
        if (
          'errors' in e &&
          e.errors &&
          typeof e.errors === 'object' &&
          'email' in e.errors &&
          typeof e.errors.email === 'string'
        ) {
          return setError('root', {
            type: 'server',
            message: e.errors.email,
          });
        }

        if (typeof e.message === 'string') {
          return setError('root', {
            type: 'server',
            message: e.message,
          });
        }
      }

      return setError('root', {
        type: 'server',
        message: 'Something went wrong, please try again later',
      });
    }
  };

  return (
    <div className="bg-white rounded-3xl shadow-xl p-10 reset-card">
      <h3 className="mt-4 text-2xl font-bold text-center">
        Sign in to your account
      </h3>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {rootMessage && (
          <ErrorAlert message={rootMessage} onClose={() => clearErrors()} />
        )}

        {message?.type === 'success' && message?.text && (
          <SuccessAlert
            message={message?.text}
            onClose={() => dispatch(setMessage(undefined))}
          />
        )}

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div className="input-wrapper mt-4 flex flex-col">
              <input
                type="email"
                placeholder="Email Address"
                className={`w-full px-4 py-2 border border-gray-300 rounded-lg outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 ${
                  error?.message ? 'border-red-500 ring-red-200' : ''
                }`}
                {...field}
              />
              <p
                className={`error-msg text-red-500 text-sm mt-1 ${
                  error?.message ? '' : 'hidden'
                }`}
              >
                {error?.message}
              </p>
            </div>
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div className="input-wrapper mt-4 flex flex-col">
              <input
                type="password"
                placeholder="••••••"
                className={`w-full px-4 py-2 border border-gray-300 rounded-lg outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200 ${
                  error?.message ? 'border-red-500 ring-red-200' : ''
                }`}
                {...field}
              />
              <p
                className={`error-msg text-red-500 text-sm mt-1 ${
                  error?.message ? '' : 'hidden'
                }`}
              >
                {error?.message}
              </p>
            </div>
          )}
        />

        <ForgotPassword />

        <SubmitButton control={control} />

        <div className="mt-4 text-center text-sm">
          <p className="text-gray-500">or</p>
        </div>

        <ContinueWithGoogle />

        <SignUp />
      </form>
    </div>
  );
};

export default Login;
