'use client';

import React from 'react';
import { sendGTMEvent } from '@next/third-parties/google';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { GoogleButton } from '@shared/ui/tailwind/google-button';
import { useGetGoogleAuthUrlMutation } from '@shared/services/auth';

export const ContinueWithGoogle: React.FC = () => {
  const postHog = useLocalPostHog();
  const [submit, res] = useGetGoogleAuthUrlMutation();

  React.useEffect(() => {
    if (res.status === 'fulfilled') {
      window.location.href = res.data.data.target_url;
    }
  }, [res]);

  const onClick = () => {
    submit();

    sendGTMEvent({ event: 'google_login_click' });

    postHog?.capture(PosthogEvents.google_login_click);
  };

  const isLoading = res.isLoading || res.isSuccess;

  return (
    <div className="mt-4 grid space-y-4">
      <GoogleButton type="button" disabled={isLoading} onClick={onClick}>
        Continue with Google
      </GoogleButton>
    </div>
  );
};
